import { Injectable } from '@angular/core';
import { Ticket } from '../interfaces/ticket';
import { ProxyService } from '../providers/proxy/proxy.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private apiName = 'bptRestApi01';
  private path = '/tickets';
  private numsTickets: number[] = [];

  constructor(
    private proxy: ProxyService
  ) { }

  public async create(ticket: FormData) {
    return await this.proxy.post('/ticket', ticket);
  }

  public async update(id: number, ticket: any) {
    return await this.proxy.post('/ticket/' + id, ticket);
  }

  public async sendComment(comment: FormData) {
    return await this.proxy.post('/ticket-comment', comment);
  }

  /* public async getList() {
    return await this.proxy.get('/tickets');
  } */
  public async getList(pagina,filtro, usuario, columnas, nocerrados) {
    return await this.proxy.get('/tickets/'+'?pagina='+pagina+'&filtro='+filtro+'&usuario='+usuario+'&columnas='+columnas+'&nocerrados='+nocerrados);
  }

  public async getListExcel(pagina,filtro, usuario, columnas, nocerrados, excel) {
    return await this.proxy.get('/tickets/'+'?pagina='+pagina+'&filtro='+filtro+'&usuario='+usuario+'&columnas='+columnas+'&nocerrados='+nocerrados+'&excel='+excel);
  }

  public async getItem(key, query = {}) {
    return await this.proxy.get('/ticket/' + key);
  }

  public getExcel(key) {
    return this.proxy.get('/tickets/descargar/'+ key);
  }

  public getReporte(fechas) {
    return this.proxy.get('/tickets/reporte/'+ '?fechas='+ fechas);
  }

  public getReporteArea(param) {
    return this.proxy.get('/tickets/reporte-area/'+ '?param='+ param);
  }



  public deleteAdjunto(idadjunto) {
    return this.proxy.get('/tickets/delete-adjunto/'+'?adjunto='+idadjunto);
  }

  public async deleteTicket(idticket) {
    return await this.proxy.get('/ticket-delete/'+'?idticket='+idticket);
  }

  public async updateComment(id: number, comment: string) {
    const encodedComment = encodeURIComponent(comment);
    return await this.proxy.get('/tickets/update-comment/?idcom=' + id + '&contenido=' + encodedComment);
  }
  

  setTicketNums(nums: number[]) {
    this.numsTickets = nums;
  }

  getTicketNums(): number[] {
    return this.numsTickets;
  }

  public async notifyTicket(idticket, tipo: string, enviar_a: string= '') {

    return await this.proxy.get('/tickets-notify/?idticket='+idticket+'&tipo='+tipo+'&enviar_a='+enviar_a);
  }
  
}
